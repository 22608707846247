import * as React from 'react';
import { useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import LogoImage from '../../images/logo.svg';
import LogoImageSP from '../../images/logo_sp.svg';
import MailIcon from '../../images/icon_mail.svg';
import MenuIcon from '../../images/icon_menu.svg';
import ArrowRightIcon from '../../images/icon_case_arrow_right.svg';
import { Button } from '../ui/Button';

const globalNavigationList = [
  {
    label: 'サービスについて',
    href: '#about',
  },
  {
    label: '特長と機能',
    href: '#feature',
  },
  {
    label: '導入事例',
    href: '#case',
  },
  {
    label: 'ご利用の流れ',
    href: '#flow',
  },
  {
    label: 'お知らせ',
    href: '#news',
  },
];

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="fixed top-0 w-full bg-white px-5 md:px-7.5 h-15 md:h-20 flex items-center justify-between z-20">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="outline-none relative md:hidden hover:curser-pointer"
      >
        <div
          className={classNames(
            'flex items-center justify-center w-6 h-6 duration-300',
            isOpen ? 'open' : ''
          )}
        >
          <MenuIcon />
        </div>
      </button>
      <h1 className="">
        <Link to="/">
          <div className="lg:hidden">
            <LogoImageSP />
          </div>
          <div className="hidden lg:block">
            <LogoImage />
          </div>
        </Link>
      </h1>
      <nav className="flex items-center">
        <ul className="hidden md:flex items-center md:text-fdm-xxs lg:text-sm md:space-x-3 lg:space-x-10">
          {globalNavigationList.map(item => (
            <li key={item.label}>
              <AnchorLink
                className="hover:opacity-60 transition-opacity duration-300"
                to={`/${item.href}`}
                onAnchorLinkClick={() => setIsOpen(false)}
              >
                {item.label}
              </AnchorLink>
            </li>
          ))}
        </ul>
        <div className="md:ml-4 lg:ml-10">
          <Link to="/contact">
            <span className="hidden md:inline-block border-2 border-primary text-primary leading-none md:text-xs lg:text-sm font-semibold md:px-3 lg:px-4 py-2 rounded-full duration-300 hover:shadow">
              お問い合わせ
            </span>
            <span className="md:hidden">
              <MailIcon />
            </span>
          </Link>
        </div>
      </nav>

      <div
        className={classNames(
          'fixed flex flex-col md:hidden bg-white top-15 bottom-0 left-0 right-0 pt-10 pb-9 px-7.5 z-10 duration-300',
          isOpen ? 'visible opacity-100' : 'invisible opacity-0'
        )}
      >
        <nav className="flex-1">
          <ul>
            {globalNavigationList.map((item, i) => (
              <li
                key={item.label}
                className={i !== 0 ? 'border-t border-gray-lightest' : ''}
              >
                <AnchorLink
                  className="relative block pt-4 pb-5 hover:opacity-60 transition-opacity duration-300"
                  to={`/${item.href}`}
                  onAnchorLinkClick={() => setIsOpen(false)}
                >
                  {item.label}
                  <div className="absolute bottom-6 right-0">
                    <ArrowRightIcon />
                  </div>
                </AnchorLink>
              </li>
            ))}
          </ul>
          <div className="mt-20 mx-auto max-w-xxs text-center">
            <Button href="https://form.run/@fdm" className="w-full">
              お問い合わせ
            </Button>
          </div>
        </nav>
        <div className="mt-10 text-fdm-xxs text-center tracking-wide leading-loose">
          &copy; LisaTechnologies inc.
        </div>
      </div>
    </header>
  );
};

export default Header;
