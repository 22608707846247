import * as React from 'react';
import { Helmet } from 'react-helmet';
import Header from './Header';
import Footer from './Footer';

type LayoutProps = {
  pageTitle: string;
  children: React.ReactNode;
};

const Layout: React.VFC<LayoutProps> = ({ pageTitle, children }) => (
  <>
    <Helmet>
      <html lang="ja" />
      <title>{pageTitle}</title>
    </Helmet>
    <div className="relative pt-15 md:pt-20 font-body text-gray">
      <Header />
      <main className="z-10">{children}</main>
      <Footer />
    </div>
  </>
);

export default Layout;
