import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

type ButtonProps = {
  href?: string;
  className?: string;
  children: React.ReactNode;
  color?: 'primary' | 'gray' | 'default';
  size?: 'xs' | 'sm' | 'default';
};

export const Button: React.VFC<ButtonProps> = ({href, className, color = 'default', size = 'default', children}) => {
  const baseClass = 'inline-block rounded-full tracking-wide leading-normal text-center px-4 duration-300';

  let buttonClass = classNames(baseClass, className);

  switch (color) {
    case 'primary':
      buttonClass = classNames(buttonClass, 'bg-primary text-white border-primary hover:opacity-60 hover:shadow');
      break;
    case 'gray':
      buttonClass = classNames(buttonClass, 'bg-white border-gray-lightest hover:opacity-60');
      break;
    default:
      buttonClass = classNames(buttonClass, 'bg-white text-primary border-primary hover:shadow');
      break;
  }

  switch (size) {
    case 'xs':
      buttonClass = classNames(buttonClass, 'py-2 border text-sm');
      break;
    case 'sm':
      buttonClass = classNames(buttonClass, 'py-2.5 md:py-3 border-2 text-sm font-bold');
      break;
    default:
      buttonClass = classNames(buttonClass, 'py-2.5 md:py-5 border-2 font-bold');
      break;
  }

  return href
    ? (
      <Link to={href} className={buttonClass}>{children}</Link>
    ) : (
      <div className={buttonClass}>
        <p>{children}</p>
      </div>
    )
};

