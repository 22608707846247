import * as React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'gatsby';
import LogoImage from '../../images/logo.svg';
import LogoImageSP from '../../images/logo_sp.svg';
import LinkIcon from '../../images/icon_link.svg';
import ToTopBtn from '../../images/btn_top.svg';

const companyData = {
  name: 'Lisa Technologies 株式会社',
  address: '〒103-0004 東京都中央区東日本橋2丁目24−9 LIT 4F',
};

const footerNavigationList = [
  {
    label: '運営会社',
    href: 'https://lisatech.jp/',
  },
  {
    label: 'プライバシーポリシー',
    href: 'https://lisatech.jp/privacy/',
  },
  {
    label: 'お問い合わせ',
    href: '/contact',
  },
];

const Footer = () => (
  <footer className="pt-10 pb-7.5">
    <div className="fixed bottom-5 right-4">
      <button
        className="outline-none hover:opacity-60 duration-300"
        type="button"
        onClick={() => scroll.scrollToTop()}
      >
        <ToTopBtn />
      </button>
    </div>
    <div className="container">
      <div className="text-center md:text-left">
        <Link to="/" className="inline-block">
          <div className="lg:hidden">
            <LogoImageSP />
          </div>
          <div className="hidden lg:block">
            <LogoImage />
          </div>
        </Link>
      </div>
      <div className="mt-3.75 md:flex justify-between">
        <dl className="text-xs leading-relaxed text-center md:text-left">
          <dt className="sr-only">運営会社</dt>
          <dd>{companyData.name}</dd>
          <dt className="sr-only">所在地</dt>
          <dd>{companyData.address}</dd>
        </dl>
        <nav className="mt-7.5 md:mt-0">
          <ul className="text-xs flex justify-center space-x-5 md:space-x-10">
            {footerNavigationList.map(item => (
              <li key={item.label}>
                {item.href.match(/https:/) ? (
                  <a
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center hover:cursor-pointer hover:opacity-60 transition-opacity duration-300"
                  >
                    <span className="mr-1">{item.label}</span>
                    <LinkIcon />
                  </a>
                ) : (
                  <Link
                    to={item.href}
                    className="flex items-center hover:cursor-pointer hover:opacity-60 transition-opacity duration-300"
                  >
                    {item.label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="mt-7.5 pt-7.5 border-t border-gray-lightest text-fdm-xxs text-center tracking-wide leading-loose">
        &copy; LisaTechnologies inc.
      </div>
    </div>
  </footer>
);

export default Footer;
